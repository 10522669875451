// TODO: Convert to TS and refactor
export const openPopupCenter = (url, title, w, h) => {
  // Credit - https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
  const { innerWidth, innerHeight, screenTop, screenLeft } = window;
  const { clientWidth, clientHeight } = document.documentElement;

  const dualScreenLeft = screenLeft !== undefined ? screenLeft : screen.left; // eslint-disable-line
  const dualScreenTop = screenTop !== undefined ? screenTop : screen.top; // eslint-disable-line

  const width = innerWidth ? innerWidth : clientWidth ? clientWidth : screen.width; // eslint-disable-line
  const height = innerHeight ? innerHeight : clientHeight ? clientHeight : screen.height; // eslint-disable-line

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left,
  );

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
};
